<template>
  <div>
    <Loading />

    <div>
      <header>
        <div class="header-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 d-none d-lg-block d-md-block">
                <p style="margin-bottom: 0rem">
                  <span style="margin-left: 10px" id="address_header"></span>
                 <a style="color:#fff;cursor:pointer" id="phone_header2"><span style="margin-left: 10px;" id="phone_header"></span></a> 
                </p>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="header-top-right">
                  <div class="content">
                    <router-link
                      aria-label="title"
                      to="/ContactUs"
                      id="welcom_header"
                    >
                    </router-link>
                  </div>
                  <div class="content lang_div_med" v-show="!showHide">
                    <a
                      aria-label="Change Language"
                      class="langBtn"
                      @click="Lang('Ar')"
                      >العربية</a
                    >
                  </div>
                  <div class="content lang_div_med" v-show="showHide">
                    <a
                      aria-label="Change Language"
                      class="langBtn"
                      @click="Lang('En')"
                      >English</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-logo-menu sticker">
          <div class="container">
            <div class="row">
              <div class="col-lg-2 col-12">
                <div class="logo">
                  <router-link href="#" aria-label="title" to="/">
                    <img
                      loading="lazy"
                      width="270"
                      height="70"
                      id="logo_image_newTouch"
                      v-bind:src="TopLogoUrl"
                      alt=""
                    />
                  </router-link>
                </div>
              </div>
              <div class="col-lg-10 col-12">
                <div class="mainmenu-area pull-left" style="text-align: left">
                  <div class="mainmenu d-none d-lg-block">
                    <nav>
                      <ul id="nav">
                        <li class="current">
                          <router-link href="#" aria-label="title" to="/">{{
                            home
                          }}</router-link>
                        </li>
                        <li>
                          <a href="#">{{ aboutUs }} </a>
                          <ul class="sub-menu" style="width: 280px">
                            <li v-for="leader in Leader" :key="leader.postId">
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Leader-with-id-And-Type',
                                  params: {
                                    type: 'Univ.Home.Leader',
                                    id: leader.postID,
                                  },
                                }"
                              >
                                {{ leader.postTitle
                                }}<i :class="leader.icon1"></i
                              ></router-link>
                            </li>
                            <li
                              v-for="aboutdata in AboutData"
                              :key="aboutdata.postId"
                            >
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Page-with-id-And-Type',
                                  params: {
                                    type: 'Univ.Home.AboutMenue',
                                    id: aboutdata.postID,
                                  },
                                }"
                              >
                                {{ aboutdata.postTitle
                                }}<i :class="aboutdata.icon1"></i
                              ></router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- for management -->
                        <li>
                          <a href="#">{{ Management }} </a>
                          <ul class="sub-menu" style="width: 280px">
                            <li
                              v-for="managedata in ManagementData"
                              :key="managedata.postId"
                            >
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Page-with-id-And-Type',
                                  params: {
                                    type: 'Univ.Managments',
                                    id: managedata.postID,
                                  },
                                }"
                              >
                                {{ managedata.postTitle
                                }}<i :class="managedata.icon1"></i
                              ></router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- end management   -->
                        <!-- Faculty -->
                        <li>
                          <a href="#">{{ colege }}</a>
                          <ul class="sub-menu" style="width: 288px">
                            <li v-for="faculty in Faculty" :key="faculty.BrnId">
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Faculties-with-id',
                                  params: {
                                    id: faculty.BrnId,
                                  },
                                }"
                              >
                                {{ faculty.Faculty }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- Faculty end -->

                        <!-- Deans -->
                        <li>
                          <a href="#">{{ dean }}</a>
                          <ul class="sub-menu" style="width: 288px">
                            <li v-for="dean in Deans" :key="dean.BrnId">
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Deans-with-id',
                                  params: {
                                    id: dean.BrnId,
                                  },
                                }"
                              >
                                {{ dean.Dean }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- Deans end -->
                        <!-- Research -->
                        <li>
                          <a href="#">{{ ScientificResearch }}</a>
                          <ul class="sub-menu" style="width: 288px">
                            <!-- <li
                              v-for="(research, index) in Research"
                              :key="research.BrnId"
                            >
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'research-with-id',
                                  params: {
                                    id: research.BrnId,
                                  },
                                }"
                              >
                                {{ research.Faculty }}
                              </router-link>
                            </li> -->
                            <li
  v-for="(research, index) in Research"
  :key="research.BrnId"
>
  <template v-if="index === 0">
    <a
      href="https://magazine.yunive.com/index.php/ojsdata"
      aria-label="title"
      target="_blank"
    >
      {{ research.Faculty }}
    </a>
  </template>
  <template v-else>
    <router-link
      href="#"
      aria-label="title"
      :to="{
        name: 'research-with-id',
        params: {
          id: research.BrnId,
        },
      }"
    >
      {{ research.Faculty }}
    </router-link>
  </template>
</li>
                          </ul>
                        </li>
                        <!-- Research end -->
                        <!-- Center -->
                        <li>
                          <a
                            href="javascript:voi
                        d(0)"
                            >{{ cnter }}</a
                          >
                          <ul class="sub-menu" style="width: 288px">
                            <li v-for="Center in Centers" :key="Center.BrnId">
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Centers-with-id',
                                  params: {
                                    id: Center.BrnId,
                                  },
                                }"
                              >
                                {{ Center.Faculty }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- Center end -->

                        <!-- service -->
                        <li>
                          <a href="#">{{ Service }} </a>
                          <ul class="sub-menu" style="width: 288px">
                            <li
                              v-for="service in ServiceData"
                              :key="service.postID"
                            >
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'Service',
                                  params: {
                                    id: service.postID,
                                    type: service.modID,
                                  },
                                }"
                              >
                                {{ service.postTitle }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- end service -->

                        <!-- lab -->
                        <li>
                          <a href="#">{{ Labs }} </a>
                          <ul class="sub-menu" style="width: 288px">
                            <li v-for="labs in ComputerLabs" :key="labs.postID">
                              <router-link
                                href="#"
                                aria-label="title"
                                :to="{
                                  name: 'ComputerLabs',
                                  params: {
                                    id: labs.postID,
                                  },
                                }"
                              >
                                {{ labs.postTitle }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                        <!-- end lab -->

                        <li class="current">
                          <a
                            href="https://www.yunive.com/student/"
                            target="_blank"
                            >{{ portal }}
                          </a>
                        </li>

                        <!-- <li style="margin-left: 24px">
                        <router-link to="/ContactUs"
                          >{{ contact2 }}
                        </router-link>
                      </li> -->
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Menu Area start -->
        <div class="mobile-menu-area">
          <div class="container clearfix">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mobile-menu">
                  <nav id="dropdown" style="display:none">
                    <ul>
                      <li class="current">
                        <router-link href="#" aria-label="title" to="/">{{
                          home
                        }}</router-link>
                      </li>

                      <li>
                        <a href="#">{{ aboutUs }} </a>
                        <ul class="sub-menu">
                          <!-- <li v-for="leader in Leader" :key="leader.postId">
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'Leader-with-id-And-Type',
                                params: {
                                  type: 'Univ.Home.Leader',
                                  id: leader.postID,
                                },
                              }"
                            >
                              {{ leader.postTitle }}<i :class="leader.icon1"></i
                            ></router-link>
                          </li> -->

                          <li
                            v-for="aboutdata in AboutData"
                            :key="aboutdata.postId"
                          >
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'Page-with-id-And-Type',
                                params: {
                                  type: 'Univ.Home.AboutMenue',
                                  id: aboutdata.postID,
                                },
                              }"
                            >
                              {{ aboutdata.postTitle
                              }}<i :class="aboutdata.icon1"></i
                            ></router-link>
                          </li>
                        </ul>
                      </li>

                      <!-- fore management  -->
                      <li>
                        <a href="#">{{ Management }} </a>
                        <ul class="sub-menu">
                          <li
                            v-for="managedata in ManagementData"
                            :key="managedata.postId"
                          >
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'Page-with-id-And-Type',
                                params: {
                                  type: 'Univ.Managments',
                                  id: managedata.postID,
                                },
                              }"
                            >
                              {{ managedata.postTitle
                              }}<i :class="managedata.icon1"></i
                            ></router-link>
                          </li>
                        </ul>
                      </li>
                      <!-- end management -->
                      <li>
                        <a href="#">{{ colege }}</a>
                        <ul class="sub-menu">
                          <li v-for="faculty in Faculty" :key="faculty.BrnId">
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'Faculties-with-id',
                                params: {
                                  id: faculty.BrnId,
                                },
                              }"
                            >
                              {{ faculty.Faculty }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <!-- Deans -->
                      <li>
                        <a href="#">{{ dean }}</a>
                        <ul class="sub-menu" style="width: 288px">
                          <li v-for="dean in Deans" :key="dean.BrnId">
                            <router-link
                              href="#"
                              aria-label="title"
                              :to="{
                                name: 'Deans-with-id',
                                params: {
                                  id: dean.BrnId,
                                },
                              }"
                            >
                              {{ dean.Dean }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <!-- Deans end -->
                      <li>
                        <a href="#">{{ ScientificResearch }}</a>
                        <ul class="sub-menu" style="width: 288px">
                          <!-- <li
                            v-for="research in Research"
                            :key="research.BrnId"
                          >
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'research-with-id',
                                params: {
                                  id: research.BrnId,
                                },
                              }"
                            >
                              {{ research.Faculty }}
                            </router-link>
                          </li> -->
                          <li
  v-for="(research, index) in Research"
  :key="research.BrnId"
>
  <template v-if="index === 0">
    <a
      href="https://magazine.yunive.com/index.php/ojsdata"
      aria-label="magazine"
    target="_blank"
    >
      {{ research.Faculty }}
    </a>
  </template>
  <template v-else>
    <router-link
      href="#"
      aria-label="title"
      :to="{
        name: 'research-with-id',
        params: {
          id: research.BrnId,
        },
      }"
    >
      {{ research.Faculty }}
    </router-link>
  </template>
</li>
                        </ul>
                      </li>
                      <!-- Center -->
                      <li>
                        <a href="#">{{ cnter }}</a>
                        <ul class="sub-menu" style="width: 288px">
                          <li v-for="Center in Centers" :key="Center.BrnId">
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'Centers-with-id',
                                params: {
                                  id: Center.BrnId,
                                },
                              }"
                            >
                              {{ Center.Faculty }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <!-- Center end -->

                      <!-- service -->
                      <li>
                        <a href="#">{{ Service }} </a>
                        <ul class="sub-menu" style="width: 288px">
                          <li
                            v-for="service in ServiceData"
                            :key="service.postID"
                          >
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              :to="{
                                name: 'Service',
                                params: {
                                  id: service.postID,
                                  type: service.modID,
                                },
                              }"
                            >
                              {{ service.postTitle }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <!-- end service -->
                      <!-- lab -->
                      <li>
                        <a href="#">{{ Labs }} </a>
                        <ul class="sub-menu" style="width: 288px">
                          <li v-for="labs in ComputerLabs" :key="labs.postID">
                            <router-link
                              href="#"
                              aria-label="title"
                              :to="{
                                name: 'ComputerLabs',
                                params: {
                                  id: labs.postID,
                                },
                              }"
                            >
                              {{ labs.postTitle }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <!-- end lab -->
                      <li class="current">
                        <a
                          href="https://www.yunive.com/student/"
                          target="_blank"
                          >{{ portal }}
                        </a>
                      </li>

                      <li>
                        <router-link
                          href="#"
                          aria-label="title"
                          to="/ContactUs"
                        >
                          {{ contact2 }}</router-link
                        >
                        <ul class="sub-menu">
                          <li>
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              to="/ContactUs"
                              >الموقع الجغرافى</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              to="/ContactUs"
                              >ارقام التواصل</router-link
                            >
                          </li>
                          <li>
                            <router-link
                              href="#"
                              style="font-weight: 500"
                              aria-label="title"
                              to="/ContactUs"
                            >
                              ارسال رسالة</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li>
                        <!-- mobile -->
                        <a href="#"> {{ language }}</a>

                        <ul class="sub-menu">
                          <li>
                            <router-link aria-label="title" to="/changeLang/Ar">
                              العربية</router-link
                            >
                          </li>
                          <li>
                            <router-link aria-label="title" to="/changeLang/En">
                              English</router-link
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile Menu Area End -->
      </header>
      <router-view :key="$route.fullPath"></router-view>

      <div
        class="newsletter-area newsletter-area_3"
        style="border-radius: 0 !important; background: transparent"
      >
        <div class="container">
          <div class="row rowFooter">
            <div class="col-lg-5 col-md-5">
              <div class="newsletter-content">
                <h3 id="postTitleFooter"></h3>
                <h2 id="postSubTitleFooter"></h2>
              </div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div class="newsletter-form angle d-none">
                <form
                  action="#"
                  id="mc-form"
                  class="mc-form footer-newsletter fix"
                >
                  <div class="subscribe-form">
                    <input
                      id="mc-email "
                      type="email "
                      name="email "
                      placeholder="ادخل البريد الالكترونى"
                    />
                    <button id="mc-submit " type="submit">الإشتراك</button>
                  </div>
                </form>
                <div class="mailchimp-alerts text-centre fix pull-left">
                  <div class="mailchimp-submitting"></div>
                  <div class="mailchimp-success"></div>
                  <div class="mailchimp-error"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-widget-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="single-footer-widget">
                <div class="footer-logo">
                  <router-link href="#" aria-label="title" to="/">
                    <img
                      loading="lazy"
                      width="268"
                      height="60"
                      v-bind:src="bottomLogoUrl"
                      alt=""
                  /></router-link>
                </div>
                <p id="postDetails"></p>
                <div class="social-icons">
                  <a
                    href=""
                    aria-label="facebook"
                    id="facebook_footer"
                    target="_blank"
                    ><img
                      width="32"
                      loading="lazy"
                      height="32"
                      src="/img/facebook.svg"
                      alt=""
                    />
                  </a>
                  <a aria-label="instagram" id="instgram_footer" target="_blank"
                    ><img
                      loading="lazy"
                      width="32"
                      height="32"
                      src="/img/instagram.svg"
                      alt=""
                  /></a>
                  <a aria-label="twitter" id="twitter_footer" target="_blank"
                    ><img
                      loading="lazy"
                      width="32"
                      height="32"
                      src="/img/twitter.svg"
                      alt=""
                  /></a>
                  <a aria-label="youtube" id="youtube_footer" target="_blank"
                    ><img
                      loading="lazy"
                      width="32"
                      height="32"
                      src="/img/youtube.svg"
                      alt=""
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-footer-widget">
                <h3>{{ contct }}</h3>
                <!-- <a id="f11_footer" href="tel:0123-456-789 "></a> -->
                <a aria-label="f21" id="f21_footer"></a>
                <a aria-label="f31" id="f31_footer"></a>
                <a aria-label="f41" target="_blank" id="f41_footer"></a>
                <a target="_blank" href="https://yuniv.net/" id="f51_footer"></a>
                <span id="f61_footer"></span>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-footer-widget">
                <h3>{{ browseMore }}</h3>
                <ul class="footer-list">
                  <li>
                    <router-link aria-label="title" to="/">{{
                      home
                    }}</router-link>
                  </li>

                  <li v-for="leader in Leader" :key="leader.postId">
                    <router-link
                      aria-label="title"
                      :to="{
                        name: 'Leader-with-id-And-Type',
                        params: {
                          type: 'Univ.Home.Leader',
                          id: leader.postID,
                        },
                      }"
                    >
                      {{ leader.postTitle }}</router-link
                    >
                  </li>

                  <li v-for="aboutdata in AboutData" :key="aboutdata.postId">
                    <router-link
                      aria-label="title"
                      :to="{
                        name: 'Page-with-id-And-Type',
                        params: {
                          type: 'Univ.Home.AboutMenue',
                          id: aboutdata.postID,
                        },
                      }"
                    >
                      {{ aboutdata.postTitle }}</router-link
                    >
                  </li>

                  <li>
                    <router-link aria-label="title" to="/ContactUs"
                      >{{ contact2 }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-footer-widget">
                <h3>{{ colege }}</h3>
                <ul class="footer-list">
                  <li v-for="faculty in Faculty" :key="faculty.BrnId">
                    <router-link
                      aria-label="title"
                      :to="{
                        name: 'Faculties-with-id',
                        params: {
                          id: faculty.BrnId,
                        },
                      }"
                    >
                      {{ faculty.Faculty }}
                    </router-link>
                  </li>

                  <li>
                    <router-link aria-label="title" to="/ContactUs"
                      >{{ contact2 }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer-area" style="border-radius: 0 !important">
        <div class="container">
          <div class="row flex_direction">
            <div class="col-lg-6 col-md-7 col-12">
              <span style="float:right;direction:rtl"
                  >&copy;{{ currentYear }} كافة الحقوق محفوظة <a
                    class="link_newtouch"
                    href="http://newtouch.dev/"
                    target="_blank"
                  >نيوتاتش للحلول التقنية الذكية</a>
                </span>
            </div>
            <div class="col-lg-6 col-md-5 col-12">
              <div class="column-right">
                <span style="float:left;direction:ltr"
                  >&copy;{{ currentYear }} All rights reserved<a
                    class="link_newtouch"
                    href="http://newtouch.dev/"
                    target="_blank"
                  >
                    NewTouch
                  Smart Technology Solutions</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Loading from "./components/Loading";
import axios from "axios";
import ArJson from "../public/i18n/Ar.json";
import EnJson from "../public/i18n/En.json";


export default {
  components: {
    Loading,
  },
  data() {
    return {
      TopLogoUrl: "",
      bottomLogoUrl: "",
      showHide: true,
      ScientificResearch: "",
      FooterData: [],
      AboutData: [],
      Leader: [],
      CenterData: [],
      Gellery: [],
      Faculty: [],
      Deans: [],
      Centers: [],
      Research: [],
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      aboutUs: "",
      cnter: "",
      colege: "",
      dean: "",
      portal: "",
      contct: "",
      language: "",
      browseMore: "",
      copyRight: "",
      currentYear: new Date().getFullYear(),
      Management: "",
      Service: "",
      Labs: "",
      contact2: "",
      ServiceData: [],
      ComputerLabs: [],
      ManagementData: [],
    };
  },
  mounted() {

        // eslint-disable-next-line no-console
    var self = this;
    if (localStorage.getItem("countervisit") == null) {
      localStorage.setItem("countervisit", "false");
    } else {
      localStorage.setItem("countervisit", "true");
    }
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            self.translate = self.ArTranslat;
            document.getElementById("langfilewebsites")?.remove();
            self.getApis(response.data[0]["LangId"]);
            self.showHide=true;
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "./style-ltr.css");
            document.head.appendChild(link1);
            self.getApis(localStorage.getItem("lang"));
            self.showHide=false;
          }
        })
        .catch(function () {});
    } else {

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        document.getElementById("langfilewebsites")?.remove();
        self.getApis("Ar");
        self.showHide=true;
        // self.lang();
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
        self.getApis("En");
        self.showHide=false;
        // self.lang();
      }
    }
  },
  methods: {
    Lang(lang) {
      localStorage.setItem("lang", lang);
      location.replace('/');
      // if (localStorage.getItem("Changelang") == "true") {
      //   location.reload();
      //   localStorage.setItem("Changelang", "false");
      // }
    },
    getApis(lang) {
      var self = this;
      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.aboutUs = self.translate[0]["HeaderAndFooter"]["about"];
      self.colege = self.translate[0]["HeaderAndFooter"]["college"];
      self.dean = self.translate[0]["HeaderAndFooter"]["deans"];
      self.cnter = self.translate[0]["HeaderAndFooter"]["centers"];
      self.portal = self.translate[0]["HeaderAndFooter"]["studentPortal"];
      self.contct = self.translate[0]["HeaderAndFooter"]["contact"];
      self.language = self.translate[0]["HeaderAndFooter"]["Languages"];
      self.browseMore = self.translate[0]["HeaderAndFooter"]["browseMore"];
      self.copyRight = self.translate[0]["HeaderAndFooter"]["copyRight"];
      self.contact2 = self.translate[0]["HeaderAndFooter"]["contact2"];
      self.Service = self.translate[0]["HeaderAndFooter"]["Service"];
      self.Labs = self.translate[0]["HeaderAndFooter"]["Labs"];
      self.Management = self.translate[0]["HeaderAndFooter"]["Management"];
      self.ScientificResearch =
        self.translate[0]["HeaderAndFooter"]["ScientificResearch"];

      var bodyFormHeaderData = new FormData();
      bodyFormHeaderData.append("check", "getPost");
      bodyFormHeaderData.append("Lang", lang);
      bodyFormHeaderData.append("FbrnId", "");
      bodyFormHeaderData.append("Type", "Univ.Header.Data");
      bodyFormHeaderData.append("ParentId", "NTROOT0");
      bodyFormHeaderData.append("PostName", "");
      bodyFormHeaderData.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormHeaderData,
      }).then(function (response) {
        var arr = response.data;
        if (arr.length != 0) {
          self.TopLogoUrl =
            "https://api2.yuniv.net:444/images/post/" +
            response.data[0]["postImage"];
          document.getElementById("welcom_header").innerHTML =
            '<i class="fa fa-graduation-cap"style="padding-left: 7px; font-size: 16px"></i>' +
            response.data[0]["f1"];
          document.getElementById("address_header").innerHTML =
            '<i class="fa  fa-map-marker" style="margin:0 5px;font-size:16px"></i>' + response.data[0]["f2"];
          document.getElementById("phone_header").innerHTML =
            '<i class="fa fa-phone" style="margin:0 5px;font-size:16px"></i>' + response.data[0]["f3"];
            document.getElementById("phone_header2").setAttribute("href", "tel:" + response.data[0]["f3"])
        }
      });
      var bodyFormAboutData = new FormData();
      bodyFormAboutData.append("check", "getPost");
      bodyFormAboutData.append("Lang", lang);
      bodyFormAboutData.append("FbrnId", "");
      bodyFormAboutData.append("Type", "Univ.Home.AboutMenue");
      bodyFormAboutData.append("ParentId", "NTROOT0");
      bodyFormAboutData.append("PostName", "");
      bodyFormAboutData.append("Pno", "5");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormAboutData,
      }).then(function (response) {
        self.AboutData = response.data;
      });
      var bodyFormLeaderData = new FormData();
      bodyFormLeaderData.append("check", "getPost");
      bodyFormLeaderData.append("Lang", lang);
      bodyFormLeaderData.append("FbrnId", "");
      bodyFormLeaderData.append("Type", "Univ.Home.Leader");
      bodyFormLeaderData.append("ParentId", "NTROOT0");
      bodyFormLeaderData.append("PostName", "");
      bodyFormLeaderData.append("Pno", "6");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormLeaderData,
      }).then(function (response) {
        self.Leader = response.data;
      });
      var bodyFormManagmentsData = new FormData();
      bodyFormManagmentsData.append("check", "getPost");
      bodyFormManagmentsData.append("Lang", lang);
      bodyFormManagmentsData.append("FbrnId", "");
      bodyFormManagmentsData.append("Type", "Univ.Managments");
      bodyFormManagmentsData.append("ParentId", "NTROOT0");
      bodyFormManagmentsData.append("PostName", "");
      bodyFormManagmentsData.append("Pno", "10");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormManagmentsData,
      }).then(function (response) {
        self.ManagementData = response.data;
      });
      var bodyFormFacultyData = new FormData();
      bodyFormFacultyData.append("check", "");
      bodyFormFacultyData.append("Lang", lang);
      bodyFormFacultyData.append("FbrnId", "U");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getMainSys",
        data: bodyFormFacultyData,
      }).then(function (response) {
        response.data.mainBrn.forEach((element) => {
          if (element.brnSys == "Univ.Faculty")
            self.Faculty.push({
              BrnId: element.brnId,
              Faculty: element.brnName,
            });
        });
      });
      var bodyFormDataDean = new FormData();
      bodyFormDataDean.append("check", "");
      bodyFormDataDean.append("Lang", lang);
      bodyFormDataDean.append("FbrnId", "U");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getMainSys",
        data: bodyFormDataDean,
      }).then(function (response) {
        response.data.mainBrn.forEach((element) => {
          if (element.brnSys == "Univ.Deans")
            self.Deans.push({
              BrnId: element.brnId,
              Dean: element.brnName,
            });
        });
      });
      var bodyFormResearchData = new FormData();
      bodyFormResearchData.append("check", "");
      bodyFormResearchData.append("Lang", lang);
      bodyFormResearchData.append("FbrnId", "U");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getMainSys",
        data: bodyFormResearchData,
      }).then(function (response) {
        response.data.mainBrn.forEach((element) => {
          if (element.brnSys == "Univ.Research")
            self.Research.push({
              BrnId: element.brnId,
              Faculty: element.brnName,
            });
        });
      });
      var bodyFormCentersData = new FormData();
      bodyFormCentersData.append("check", "");
      bodyFormCentersData.append("Lang", lang);
      bodyFormCentersData.append("FbrnId", "U");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getMainSys",
        data: bodyFormCentersData,
      }).then(function (response) {
        response.data.mainBrn.forEach((element) => {
          if (element.brnSys == "Univ.Centers")
            self.Centers.push({
              BrnId: element.brnId,
              Faculty: element.brnName,
            });
        });
      });
      var bodyFormServicesData = new FormData();
      bodyFormServicesData.append("check", "getPost");
      bodyFormServicesData.append("Lang", lang);
      bodyFormServicesData.append("FbrnId", "");
      bodyFormServicesData.append("Type", "Univ.Services");
      bodyFormServicesData.append("ParentId", "NTROOT0");
      bodyFormServicesData.append("PostName", "");
      bodyFormServicesData.append("Pno", "10");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormServicesData,
      }).then(function (response) {
        self.ServiceData = response.data;
      });

      var bodyFormlabsData = new FormData();
      bodyFormlabsData.append("check", "getPost");
      bodyFormlabsData.append("Lang", lang);
      bodyFormlabsData.append("FbrnId", "");
      bodyFormlabsData.append("Type", "Univ.labs");
      bodyFormlabsData.append("ParentId", "NTROOT0");
      bodyFormlabsData.append("PostName", "");
      bodyFormlabsData.append("Pno", "10");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormlabsData,
      }).then(function (response) {
        self.ComputerLabs = response.data;
      });

      var bodyFormFooterData = new FormData();
      bodyFormFooterData.append("check", "getPostDetails");
      bodyFormFooterData.append("Lang", lang);
      bodyFormFooterData.append("FbrnId", "");
      bodyFormFooterData.append("Type", "Univ.Footer.Data");
      bodyFormFooterData.append("ParentId", "NTROOT0");
      bodyFormFooterData.append("PostName", "");
      bodyFormFooterData.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormFooterData,
      })
        .then(function (response) {
          self.FooterData = response.data;
          if (self.FooterData.length != 0) {
            self.bottomLogoUrl =
              "https://api2.yuniv.net:444/images/post/" +
              self.FooterData[0]["postImage"];

            document
              .getElementById("facebook_footer")
              .setAttribute("href", response.data[0]["facebook1"]);

            document
              .getElementById("facebook_footer")
              .setAttribute("href", response.data[0]["facebook1"]);
            document
              .getElementById("instgram_footer")
              .setAttribute("href", response.data[0]["instgram1"]);
            document
              .getElementById("twitter_footer")
              .setAttribute("href", response.data[0]["tweeter1"]);
            document
              .getElementById("youtube_footer")
              .setAttribute("href", response.data[0]["youtube1"]);

            document.getElementById("postTitleFooter").innerText =
              response.data[0]["postTitle"];
            document.getElementById("postSubTitleFooter").innerText =
              response.data[0]["postSubTitle"];
            document.getElementById("f21_footer").innerHTML =
              '<i class="fa fa-phone"></i>' + response.data[0]["f21"];
            document
              .getElementById("f21_footer")
              .setAttribute("href", "tel:" + response.data[0]["f21"]);
            document.getElementById("f31_footer").innerHTML =
              '<i class="fa fa-phone"></i>' + response.data[0]["f31"];
            document
              .getElementById("f31_footer")
              .setAttribute("href", "tel:" + response.data[0]["f31"]);
            document.getElementById("f41_footer").innerHTML =
              '<i class="fa fa-envelope"></i>' + response.data[0]["f41"];
            document
              .getElementById("f41_footer")
              .setAttribute("href", "mailto:" + response.data[0]["f41"]);
            document.getElementById("f51_footer").innerHTML =
              '<i class="fa fa-globe"></i>' + response.data[0]["f51"];
            document.getElementById("f61_footer").innerHTML =
              '<i class="fa fa-map-marker"></i>' + response.data[0]["f61"];

            document.getElementById("postDetails").innerHTML =
              self.FooterData[0]["postDetails"];
          }
        })
        .catch(function () {});
    },
  },
};
</script>
<style scoped>
.langBtn {
  cursor: pointer !important;
}
.footer-list a:hover,
.single-footer-widget > a:hover {
  color: #ecdc4e !important;
}
.font_color {
  color: #0c3ef7;
}
.link_newtouch {
  color: #0c3ef7;
  font-weight: bold;
}
li i {
  color: #0c3ef7;
}
li a {
  font-weight: bold;
}
.footer-img {
  width: 80px;
  height: 80px;
}
.mean-container .mean-nav ul li a {
  background: #f8f8f8;
  color: #666;
  display: block;
  float: left;
  font-size: 12px;
  margin: 0;
  padding: 1em 5%;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  width: 90%;
  font-weight: 700;
}
.mean-container .mean-nav ul li a.mean-expand {
  width: 26px;
  height: 15px;
  margin-top: 1px;
  padding: 12px !important;
  text-align: center;
  position: absolute;
  left: 0 !important;
  top: 0;
  z-index: 2;
  font-weight: 700;
  border: 0 !important;
  background: #f8f8f8;
}
#postTitleFooter,
#postSubTitleFooter {
  color: #000;
}
#logo_image_newTouch {
  margin-top: -6px;
  max-width: 133% !important;
}
a {
  cursor: pointer;
}
</style>
